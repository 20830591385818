import { Route } from '@angular/router';

import { IsLoggedInGuard } from './auth/is-logged-in.guard';
import { LoginComponent } from './login/login.component';

export interface AdminRoute extends Route {
  showInNav: boolean;
  displayName: string;
  icon?: string;
}

export interface Divider {
  dividerName: string;
}

export const AppRoutes: (AdminRoute | Divider)[] = [
  {
    path: '',
    component: LoginComponent,
    showInNav: false,
    displayName: 'Home',
    title: 'Home',
  },
  {
    path: 'auth',
    loadChildren: () =>
      import('./auth-ui/auth-ui.module').then((x) => x.AuthUiModule),
    showInNav: false,
    displayName: 'Auth',
  },
  {
    dividerName: 'CMS',
  },
  {
    path: 'pages',
    loadChildren: () =>
      import('./pages/pages.module').then((x) => x.PagesModule),
    canActivate: [IsLoggedInGuard],
    data: {
      additionalRoles: ['CMSUser'],
    },
    showInNav: true,
    displayName: 'Pages',
    icon: 'fas fa-file-alt',
  },
  {
    path: 'page-enhancements',
    loadChildren: () =>
      import('./page-enhancements/page-enhancements.module').then(
        (x) => x.PageEnhancementsModule
      ),
    data: {
      additionalRoles: ['CMSUser'],
    },
    canActivate: [IsLoggedInGuard],
    showInNav: true,
    displayName: 'Page Enhancements',
    icon: 'fas fa-file-alt',
  },
  {
    path: 'media',
    loadChildren: () =>
      import('./media/media.module').then((x) => x.MediaModule),
    canActivate: [IsLoggedInGuard],
    showInNav: true,
    displayName: 'Media',
    icon: 'fas fa-file-alt',
  },
  {
    path: 'tags',
    loadChildren: () => import('./tags/tags.module').then((x) => x.TagsModule),
    canActivate: [IsLoggedInGuard],
    showInNav: true,
    displayName: 'Tags',
    icon: 'fas fa-file-alt',
  },
  {
    dividerName: 'Orders',
  },

  {
    path: 'orders',
    loadChildren: () =>
      import('./orders/orders.module').then((x) => x.OrdersModule),
    canActivate: [IsLoggedInGuard],
    showInNav: true,
    displayName: 'Orders',
    icon: 'fas fa-file-alt',
  },
  {
    path: 'shipping',
    loadChildren: () =>
      import('./shipping/shipping.module').then((x) => x.ShippingModule),
    canActivate: [IsLoggedInGuard],
    showInNav: true,
    displayName: 'Shipping',
    icon: 'fas fa-file-alt',
  },
  {
    path: 'subscriptions',
    loadChildren: () =>
      import('./subscriptions/subscriptions.module').then(
        (x) => x.SubscriptionsModule
      ),
    canActivate: [IsLoggedInGuard],
    showInNav: true,
    displayName: 'Subscriptions',
    icon: 'fas fa-redo',
  },
  {
    path: 'course-reports',
    loadChildren: () =>
      import('./course-reports/course-reports.module').then(
        (x) => x.CourseReportsModule
      ),
    canActivate: [IsLoggedInGuard],
    showInNav: true,
    displayName: 'Course Reports',
    icon: 'fas fa-table',
  },
  { dividerName: 'Products' },
  {
    path: 'products',
    loadChildren: () =>
      import('./products/products.module').then((x) => x.ProductsModule),
    canActivate: [IsLoggedInGuard],
    showInNav: true,
    displayName: 'Products',
    icon: 'fas fa-boxes',
  },
  {
    path: 'discounts',
    loadChildren: () =>
      import('./discounts/discounts.module').then((x) => x.DiscountsModule),
    canActivate: [IsLoggedInGuard],
    showInNav: true,
    displayName: 'Discounts',
    icon: 'fas fa-tags',
  },
  {
    path: 'sold-out-notifications',
    loadChildren: () =>
      import('./sold-out-notifications/sold-out-notifications.module').then(
        (x) => x.SoldOutNotificationsModule
      ),
    canActivate: [IsLoggedInGuard],
    showInNav: true,
    displayName: 'Sold Out Notifications',
    icon: 'fas fa-bell',
  },
  {
    path: 'vouchers',
    loadChildren: () =>
      import('./vouchers/vouchers.module').then((x) => x.VouchersModule),
    canActivate: [IsLoggedInGuard],
    showInNav: true,
    displayName: 'Product Vouchers',
    icon: 'fas fa-ticket-alt',
  },
  ,
  {
    path: 'external-orders',
    loadChildren: () =>
      import('./external-orders/external-orders.module').then(
        (x) => x.ExternalOrdersModule
      ),
    canActivate: [IsLoggedInGuard],
    showInNav: true,
    displayName: 'External Orders',
    icon: 'fas fa-file',
  },
  {
    path: 'content-type-metadata',
    loadChildren: () =>
      import('./content-type-metadata/content-type-metadata.module').then(
        (x) => x.ContentTypeMetadataModule
      ),
    canActivate: [IsLoggedInGuard],
    showInNav: true,
    displayName: 'Content Type Metadata',
    icon: 'fas fa-file-alt',
  },

  { dividerName: 'Updates' },
  {
    path: 'blogs',
    loadChildren: () =>
      import('./blogs/blogs.module').then((x) => x.BlogsModule),
    canActivate: [IsLoggedInGuard],
    showInNav: true,
    displayName: 'Blogs',
    icon: 'fas fa-file-alt',
  },
  {
    path: 'kiss-summaries',
    loadChildren: () =>
      import('./kiss-summaries/kiss-summaries.module').then(
        (x) => x.KissSummariesModule
      ),
    canActivate: [IsLoggedInGuard],
    showInNav: true,
    displayName: 'KISS Summaries',
    icon: 'fas fa-notes-medical',
  },
  {
    path: 'podcasts',
    loadChildren: () =>
      import('./podcasts/podcasts.module').then((x) => x.PodcastsModule),
    canActivate: [IsLoggedInGuard],
    showInNav: true,
    displayName: 'Podcasts',
    icon: 'fas fa-podcast',
  },
  {
    path: 'authors',
    loadChildren: () =>
      import('./authors/authors.module').then((x) => x.AuthorsModule),
    canActivate: [IsLoggedInGuard],
    showInNav: true,
    displayName: 'Authors',
    icon: 'fas fa-user-edit',
  },
  { dividerName: 'Users' },
  {
    path: 'users',
    loadChildren: () =>
      import('./users/users.module').then((x) => x.UsersModule),
    canActivate: [IsLoggedInGuard],
    showInNav: true,
    displayName: 'Users',
    icon: 'fas fa-user',
  },
  {
    path: 'user-vouchers',
    loadChildren: () =>
      import('./user-vouchers/user-vouchers.module').then(
        (x) => x.UserVouchersModule
      ),
    canActivate: [IsLoggedInGuard],
    showInNav: false,
    displayName: 'User Vouchers',
    icon: 'fas fa-ticket-alt',
  },
  { dividerName: 'Organisations' },
  {
    path: 'organisations',
    loadChildren: () =>
      import('./organisations/organisations.module').then(
        (x) => x.OrganisationsModule
      ),
    canActivate: [IsLoggedInGuard],
    showInNav: true,
    displayName: 'Organisations',
    icon: 'fas fa-building',
  },
  {
    path: 'licences',
    loadChildren: () =>
      import('./licences/licences.module').then((x) => x.LicencesModule),
    canActivate: [IsLoggedInGuard],
    showInNav: true,
    displayName: 'Licences',
    icon: 'fas fa-id-card',
  },
  {
    path: 'expiring-licences',
    loadChildren: () =>
      import('./expiring-licences/expiring-licences.module').then(
        (x) => x.ExpiringLicencesModule
      ),
    canActivate: [IsLoggedInGuard],
    showInNav: true,
    displayName: 'Expiring Licences',
    icon: 'fas fa-clock',
  },
  {
    path: 'gift-tokens',
    loadChildren: () =>
      import('./gift-tokens/gift-tokens.module').then(
        (x) => x.GiftTokensModule
      ),
    canActivate: [IsLoggedInGuard],
    showInNav: true,
    displayName: 'Gift Tokens',
    icon: 'fas fa-coins',
  },
  { dividerName: 'Question Bank' },
  {
    path: 'question-bank-questions',
    loadChildren: () =>
      import('./question-bank-questions/question-bank-questions.module').then(
        (x) => x.QuestionBankQuestionsModule
      ),
    canActivate: [IsLoggedInGuard],
    showInNav: true,
    displayName: 'Questions',
    icon: 'fas fa-question',
  },
  {
    path: 'question-bank-categories',
    loadChildren: () =>
      import('./question-bank-categories/question-bank-categories.module').then(
        (x) => x.QuestionBankCategoriesModule
      ),
    canActivate: [IsLoggedInGuard],
    showInNav: true,
    displayName: 'Categories',
    icon: 'fas fa-question',
  },
  { dividerName: 'Misc' },
  {
    path: 'locations',
    loadChildren: () =>
      import('./locations/locations.module').then((x) => x.LocationsModule),
    canActivate: [IsLoggedInGuard],
    showInNav: true,
    displayName: 'Locations',
    icon: 'fas fa-map-pin',
  },
  {
    path: 'testimonials',
    loadChildren: () =>
      import('./testimonials/testimonials.module').then(
        (x) => x.TestimonialsModule
      ),
    canActivate: [IsLoggedInGuard],
    showInNav: true,
    displayName: 'Testimonials',
    icon: 'far fa-comments',
  },
  {
    path: 'vacancies',
    loadChildren: () =>
      import('./vacancies/vacancies.module').then((x) => x.VacanciesModule),
    canActivate: [IsLoggedInGuard],
    showInNav: true,
    displayName: 'Vacancies',
    icon: 'fas fa-briefcase',
  },
  {
    path: 'faqs',
    loadChildren: () => import('./faq/faq.module').then((x) => x.FaqModule),
    canActivate: [IsLoggedInGuard],
    showInNav: true,
    displayName: 'FAQs',
    icon: 'fas fa-question',
  },
  {
    path: 'email-messages',
    loadChildren: () =>
      import('./email-messages/email-messages.module').then(
        (x) => x.EmailMessagesModule
      ),
    canActivate: [IsLoggedInGuard],
    showInNav: true,
    displayName: 'Email Messages',
    icon: 'fas fa-comment',
  },
  {
    path: 'course-notifications',
    loadChildren: () =>
      import('./course-notifications/course-notifications.module').then(
        (x) => x.CourseNotificationsModule
      ),
    canActivate: [IsLoggedInGuard],
    showInNav: true,
    displayName: 'Course Notifications',
    icon: 'fas fa-bell',
  },
  {
    path: 'mailing-list',
    loadChildren: () =>
      import('./mailing-list/mailing-list.module').then(
        (x) => x.MailingListModule
      ),
    canActivate: [IsLoggedInGuard],
    showInNav: true,
    displayName: 'Mailing List Export',
    icon: 'fas fa-envelope',
  },
  {
    path: 'audits',
    loadChildren: () =>
      import('./audits/audits.module').then((x) => x.AuditsModule),
    canActivate: [IsLoggedInGuard],
    showInNav: true,
    displayName: 'Audits',
    icon: 'fas fa-clipboard-list',
  },
  {
    path: 'job-listings',
    loadChildren: () =>
      import('./job-listings/job-listings.module').then(
        (x) => x.JobListingsModule
      ),
    canActivate: [IsLoggedInGuard],
    showInNav: true,
    displayName: 'Job Listings',
    icon: 'fas fa-list',
  },
  {
    path: 'dashboard-adverts',
    loadChildren: () =>
      import('./dashboard-adverts/dashboard-adverts.module').then(
        (x) => x.DashboardAdvertsModule
      ),
    canActivate: [IsLoggedInGuard],
    showInNav: true,
    displayName: 'Dashboard Adverts',
    icon: 'fas fa-panorama',
  },
  {
    path: 'timeline-entries',
    loadChildren: () =>
      import('./timeline-entries/timeline-entries.module').then(
        (x) => x.TimelineEntriesModule
      ),
    canActivate: [IsLoggedInGuard],
    showInNav: true,
    displayName: 'Timeline Entries',
    icon: 'fas fa-timeline',
  },
  {
    path: 'subscription-feedback',
    loadChildren: () =>
      import(
        './subscriptions/subscription-feedback/subscription-feedback.module'
      ).then((x) => x.SubscriptionFeedbackModule),
    canActivate: [IsLoggedInGuard],
    showInNav: true,
    displayName: 'Subscription Feedback',
    icon: 'fas fa-comments',
  },
  { dividerName: 'Settings' },
  {
    path: 'product-defaults',
    loadChildren: () =>
      import('./product-defaults/product-defaults.module').then(
        (x) => x.ProductDefaultsModule
      ),
    canActivate: [IsLoggedInGuard],
    showInNav: true,
    displayName: 'Product Defaults',
    icon: 'fas fa-gear',
  },
];
